<template>
  <div id="page-top">
    <!-- Masthead-->
    <header class="masthead">
      <div class="container h-100">
        <div class="row h-100 align-items-center justify-content-center text-center">
          <div class="col-lg-10 align-self-end">
            <h1 class="text-uppercase text-white font-weight-bold">苹果TF签名</h1>
            <hr class="divider my-4"/>
          </div>
          <div class="col-lg-8 align-self-baseline">
            <p class="text-white font-weight-light mb-1">TestFlight签名 ：官方签名，稳定至少3个月，可按次数计费</p>
            <p class="text-white font-weight-light mb-1">一个账号只签一个应用，稳定上架不封号，封号免费自动补号</p>
            <p class="text-white font-weight-light mb-1">应用更新通过官方渠道自动推送，高效方便，24小时售后。</p>
            <p class="text-white font-weight-light mb-2 mt-2">微信联系我们：<span style="font-size: 20px; font-weight: bold">AppleDevOps</span></p>
            <p class="text-white font-weight-light mb-2">QQ联系我们：<span style="font-size: 20px; font-weight: bold">253481396</span></p>
            <a class="btn btn-primary btn-xl js-scroll-trigger" href="/upload">开始使用</a>
          </div>
        </div>
      </div>
    </header>


    <!-- Call to action-->
    <section class="page-section bg-dark text-white">
      <div class="container text-center">
        <h2 class="mb-4">苹果App上架预审核</h2>
        <hr class="divider light my-4"/>
        <p class="text-white mb-4">检查plist的配置项：配置项已打开，应用程序中功能已正确配置。</p>
        <p class="text-white mb-4">测试应用程序功能：确保所有功能都正常工作，没有崩溃或错误。</p>
        <p class="text-white mb-4">检查应用兼容性：支持多种设备，请确保在设备上正常登录和运行。</p>
        <p class="text-white mb-4">支持IPv6：确保应用程序支持IPv6网络，以符合苹果的审核要求。</p>
        <a class="btn btn-light btn-xl" href="/upload">立刻使用</a>
      </div>
    </section>

    <!-- About-->
    <section class="page-section bg-primary" id="about">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-8 text-center">
            <h2 class="text-white mt-0">关于我们</h2>
            <hr class="divider light my-4"/>
            <p class="text-white mb-4" >来自一线大厂，提供专业的预审服务，负责几百款应用审核</p>
            <p class="text-white mb-4">涵盖游戏、社交、电商、新闻、娱乐、短视频等类别上万次应用上架</p>
            <a class="btn btn-light btn-xl js-scroll-trigger" href="/upload">开始使用!</a>
          </div>
        </div>
      </div>
    </section>
    <!-- Services-->
    <!-- Contact-->
<!--    <section class="page-section" id="contact">-->
<!--      <div class="container">-->
<!--        <div class="row justify-content-center">-->
<!--          <div class="col-lg-8 text-center">-->
<!--            <h2 class="mt-0">Let's Get In Touch!</h2>-->
<!--            <hr class="divider my-4"/>-->
<!--            <p class="text-muted mb-5">Ready to start your next project with us? Give us a call or send us an email and-->
<!--              we will get back to you as soon as possible!</p>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="row">-->
<!--          <div class="col-lg-4 ml-auto text-center mb-5 mb-lg-0">-->
<!--            <i class="fas fa-phone fa-3x mb-3 text-muted"></i>-->
<!--            <div class="contact-card">-->
<!--              <div class="contact-card-left"></div>-->
<!--              <div class="contact-card-right">-->
<!--                <span style="color: #f4623a;">群: 967949804</span>-->
<!--                <div>-->
<!--                  <a target="_blank"-->
<!--                     href="//shang.qq.com/wpa/qunwpa?idkey=8558c0f110569f497aa15e91162a236e98f39c01e8de78acce05db4d7181b37f">-->
<!--                    <img border="0" src="//pub.idqqimg.com/wpa/images/group.png" alt="TF" title="TF">-->
<!--                  </a>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->

<!--          </div>-->
<!--          <div class="col-lg-4 mr-auto text-center">-->
<!--            <i class="fas fa-envelope fa-3x mb-3 text-muted"></i-->
<!--            >&lt;!&ndash; Make sure to change the email address in BOTH the anchor text and the link target below!&ndash;&gt;-->
<!--            <a class="d-block" href="mailto:contact@yourwebsite.com" style="color: #f4623a;">contact@yourwebsite.com</a>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </section>-->
  </div>

</template>

<script>
  (function($) {
    "use strict"; // Start of use strict

    // Smooth scrolling using jQuery easing
    $('a.js-scroll-trigger[href*="#"]:not([href="#"])').click(function() {
      if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
        if (target.length) {
          $('html, body').animate({
            scrollTop: (target.offset().top - 72)
          }, 1000, "easeInOutExpo");
          return false;
        }
      }
    });

    // Closes responsive menu when a scroll trigger link is clicked
    $('.js-scroll-trigger').click(function() {
      $('.navbar-collapse').collapse('hide');
    });

    // Activate scrollspy to add active class to navbar items on scroll
    $('body').scrollspy({
      target: '#mainNav',
      offset: 75
    });

    // Collapse Navbar
    var navbarCollapse = function() {
      if( $("#mainNav").offset() && ($("#mainNav").offset().top > 100)) {
        $("#mainNav").addClass("navbar-scrolled");
      } else {
        $("#mainNav").removeClass("navbar-scrolled");
      }
    };
    // Collapse now if page is not at top
    navbarCollapse();
    // Collapse the navbar when page is scrolled
    $(window).scroll(navbarCollapse);

    // Magnific popup calls
    $('#portfolio').magnificPopup({
      delegate: 'a',
      type: 'image',
      tLoading: 'Loading image #%curr%...',
      mainClass: 'mfp-img-mobile',
      gallery: {
        enabled: true,
        navigateByImgClick: true,
        preload: [0, 1]
      },
      image: {
        tError: '<a href="%url%">The image #%curr%</a> could not be loaded.'
      }
    });

  })(jQuery); // End of use strict
  export default {
    name: "Home",
    data() {
      return {

      }
    },
    methods: {}
  }
</script>

<style scoped>

  .qq-logo {
    float: left;
    width: 35px;
    height: 30px;
    background: url(~@/assets/x-img/qq-logo.jpg) 0 0 no-repeat;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
  }

  .contact-card {
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
  }
  .contact-card-left  {
    background: url(~@/assets/x-img/test-flight-logo.png) no-repeat;
    background-size: 100px 100px;
    width: 55px;
    height: 55px;
    border-radius: 80%;

    background-position: center center;
  }
  .contact-card-right  {
    padding-left: 15px;
    flex: 1;
    -webkit-flex: 1;
    text-align: left;
  }


  /deep/ .bg-primary{
    background-color: #f4623a !important;
  }

  /deep/ .btn-primary{
    background-color: #f4623a;
  }

  /deep/ .text-primary {
    color: #f4623a !important;
  }

</style>
